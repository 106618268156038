import * as React from "react"
import { motion } from "framer-motion"
import { cn } from "@/utils/cn"
import { useLabels } from "@/hooks/useDevtool"

export default function Navbar({
  onLabelClick,
  activeLabel,
}: {
  onLabelClick: (label: string) => void
  activeLabel: string | null
}) {
  const categories = useLabels();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <div>
      <h3 className="font-medium text-gray-800 mb-3">分类导航</h3>
      <motion.nav 
        className="flex flex-col space-y-2"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {categories.map((category) => (
          <motion.div
            key={category.key}
            variants={itemVariants}
            className={cn(
              "px-3 py-2.5 rounded-lg transition-all duration-300 flex items-center cursor-pointer relative overflow-hidden",
              activeLabel === category.key 
                ? "bg-orange-500 text-white font-medium shadow-sm" 
                : "hover:bg-orange-100 text-gray-700 hover:text-orange-600"
            )}
            onClick={() => {
              onLabelClick(category.key)
            }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {activeLabel === category.key && (
              <motion.div
                className="absolute inset-0 bg-orange-500 z-0"
                layoutId="navBackground"
                initial={false}
                transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              />
            )}
            <category.icon className={cn("w-5 h-5 mr-3 z-10", 
              activeLabel === category.key 
                ? "text-white" 
                : "text-orange-500"
            )} />
            <span className="z-10">{category.label}</span>
          </motion.div>
        ))}
      </motion.nav>
    </div>
  )
}
