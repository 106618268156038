import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import type { GetStaticPropsContext } from 'next'
import { useLocalStorage } from 'react-use'
import { useTranslations } from 'next-intl'
import { Switch } from "@/components/ui/switch"
import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { AnimatePresence, motion } from "framer-motion"
import { Input } from "@/components/ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

import Header from '@/components/layouts/header'
import Navbar from '@/components/layouts/navbar'
import { Tool, useDevtools, useLabels } from '@/hooks/useDevtool'
import { getToolsByLabel } from '../utils/label'
import { ToolsBlock, StarTools } from '../components/tools'
import { Seo } from '@/components/seo'

function Home() {
  const { query, isReady } = useRouter()
  const t = useTranslations('Index')
  const devtools = useDevtools()
  const labels = useLabels()

  const [label, setLabel] = useState<string | null>(null)
  const labelRef = useRef<HTMLDivElement>(null)

  const [starTools, setStarTools] = useLocalStorage<Tool[]>('Home:Tools:ME', [])
  const [showThumbnail, setShowThumbnail] = useLocalStorage('Home:ShowThumbnail', false)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [activeView, setActiveView] = useState<'grid' | 'list'>('grid')

  useEffect(() => {
    setLabel(isReady ? query.label as string : null)
  }, [isReady, query.label])

  useEffect(() => {
    if (label && labelRef.current) {
      labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [label])

  const onStar = useCallback((tool: Tool) => {
    const isStar = starTools?.map(x => x.href).includes(tool.href)
    const tools = !isStar ? [
      ...starTools || [],
      tool,
    ] : starTools?.filter(x => x.href !== tool.href)
    setStarTools(tools)
  }, [starTools])

  // 过滤搜索结果
  const filteredTools = useMemo(() => {
    if (!searchQuery.trim()) return null;
    
    return devtools.filter(tool => 
      tool.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
      tool.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [devtools, searchQuery]);

  const heroVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <Seo
        title={t('title')}
        description={t('description')}
        path=""
      />
      <Header className="bg-white border-b" />
      
      {/* 英雄区域 */}
      <motion.div 
        className="bg-gradient-to-br from-orange-50 to-orange-100 py-12 px-4 sm:px-6 lg:px-8 mb-8"
        initial="hidden"
        animate="visible"
        variants={heroVariants}
      >
        <div className="container mx-auto max-w-4xl text-center">
          <h1 className="text-3xl font-bold text-orange-600 mb-4">{t('heroTitle')}</h1>
          <p className="text-lg text-gray-700 mb-8 max-w-2xl mx-auto">{t('heroDescription')}</p>
          
          <div className="relative max-w-lg mx-auto">
            <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <Input
              type="text"
              placeholder={t('searchPlaceholder')}
              className="pl-10 pr-4 py-4 h-12 rounded-full border-orange-200 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </motion.div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* 侧边栏 */}
          <div className="md:w-64 shrink-0">
            <div className="md:sticky md:top-20 space-y-6">
              <div className="bg-white rounded-xl shadow-sm p-4 border border-orange-100">
                <Navbar onLabelClick={setLabel} activeLabel={label} />
              </div>
              
              <div className="bg-white rounded-xl shadow-sm p-4 border border-orange-100">
                <h3 className="font-medium text-gray-800 mb-3">{t('displayOptions')}</h3>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">{t('showThumbnail')}</span>
                  <Switch
                    checked={showThumbnail}
                    onCheckedChange={setShowThumbnail}
                    className="data-[state=checked]:bg-orange-500"
                  />
                </div>
                
                <div className="mt-4">
                  <h3 className="font-medium text-gray-800 mb-3">{t('viewMode')}</h3>
                  <Tabs defaultValue="grid" value={activeView} onValueChange={(v) => setActiveView(v as 'grid' | 'list')} className="w-full">
                    <TabsList className="grid w-full grid-cols-2 bg-orange-100/50">
                      <TabsTrigger value="grid" className="data-[state=active]:bg-orange-500 data-[state=active]:text-white">{t('gridView')}</TabsTrigger>
                      <TabsTrigger value="list" className="data-[state=active]:bg-orange-500 data-[state=active]:text-white">{t('listView')}</TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          {/* 主内容区 */}
          <div className="flex-1">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={staggerContainer}
              className="space-y-8"
            >
              {/* 搜索结果 */}
              {searchQuery.trim() && (
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="bg-white rounded-xl shadow-sm p-6 border border-orange-100"
                  >
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('searchResults', { query: searchQuery })}</h2>
                    {filteredTools && filteredTools.length > 0 ? (
                      <ToolsBlock
                        label="search"
                        tools={filteredTools}
                        onStar={onStar}
                        starTools={starTools || []}
                        showThumbnail={showThumbnail}
                        viewMode={activeView}
                      />
                    ) : (
                      <p className="text-gray-500">{t('noMatchingTools')}</p>
                    )}
                  </motion.div>
                </AnimatePresence>
              )}

              {/* 收藏工具区域 */}
              {!searchQuery.trim() && starTools && starTools.length > 0 && (
                <div className="bg-white rounded-xl shadow-sm p-6 border border-orange-100">
                  <StarTools 
                    starTools={starTools || []} 
                    onStar={onStar}
                    showThumbnail={showThumbnail}
                    viewMode={activeView}
                  />
                </div>
              )}

              {/* 分类工具区域 */}
              {!searchQuery.trim() && labels.filter(label => label.key !== 'star').map((labelItem, index) => (
                <div 
                  key={labelItem.key} 
                  ref={labelItem.key === label ? labelRef : null}
                  className="bg-white rounded-xl shadow-sm p-6 border border-orange-100"
                >
                  <ToolsBlock
                    label={labelItem.key}
                    tools={getToolsByLabel(devtools, labelItem.key)}
                    onStar={onStar}
                    starTools={starTools || []}
                    showThumbnail={showThumbnail}
                    viewMode={activeView}
                  />
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export async function getStaticProps({locale}: GetStaticPropsContext) {
  return {
    props: {
      messages: (await import(`../messages/${locale}.json`)).default
    }
  };
}

export default Home
