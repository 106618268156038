import Link from 'next/link'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Tool } from '@/hooks/useDevtool'
import { useRouter } from 'next/router'
import { motion } from "framer-motion"
import { StarIcon, ExternalLinkIcon } from '@radix-ui/react-icons'

const imageLoader = ({ src, width, quality }: { src: string, width: number, quality?: number }) => {
  return `https://static.shanyue.tech/${src}?x-oss-process=image/resize,w_${width}/quality,q_${quality || 75}`
}

export function ToolsBlock({ 
  tools, 
  label, 
  onStar, 
  starTools, 
  className, 
  showThumbnail = true,
  viewMode = 'grid' 
}: { 
  tools: Tool[], 
  label: string, 
  onStar: (tool: Tool) => void, 
  starTools: Tool[], 
  className?: string, 
  showThumbnail?: boolean,
  viewMode?: 'grid' | 'list'
}) {
  const t = useTranslations('Tools')
  const labelT = useTranslations('Layout.Labels')

  const router = useRouter()
  
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <div className={className}>
      <div className="flex items-center py-3" id={label}>
        <Badge variant="outline" className="text-orange-500 border-orange-500 text-sm px-3 py-1">
          {labelT(label)} · {t('toolCount', { count: tools.length })}
        </Badge>
      </div>
      
      {viewMode === 'grid' ? (
        <div className="grid grid-cols-1 mb-8 gap-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {tools.map((tool, index) => {
            const isStar = starTools.map(x => x.href).includes(tool.href)
            return (
              <motion.div
                key={tool.href}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.05 }}
              >
                <Card
                  className="group hover:border-orange-400 hover:shadow-md hover:bg-orange-50 cursor-pointer transition-all duration-300 relative h-full flex flex-col"
                  onClick={(e) => {
                    if ((e.target as HTMLElement).closest('button')) return;
                    router.push(tool.href) 
                  }}
                >
                  <Button
                    size="icon"
                    variant={isStar ? "default" : "outline"}
                    onClick={(e) => {
                      e.stopPropagation();
                      onStar(tool);
                    }}
                    className={`absolute top-2 right-2 z-10 rounded-full w-8 h-8 ${isStar ? 'bg-orange-500 hover:bg-orange-600' : 'border-orange-200 bg-white/70 backdrop-blur-sm'}`}
                  >
                    <StarIcon className={`h-4 w-4 ${isStar ? 'text-white' : 'text-orange-500'}`} />
                  </Button>
                  
                  {showThumbnail && (
                    <CardContent className="p-0 flex-shrink-0">
                      <Link href={tool.href} className="block overflow-hidden rounded-t-lg" prefetch={false}>
                        {!tool.thumbnail.startsWith('images/') ? (
                          <Image
                            alt={`${tool.name} thumbnail`}
                            src={require(`../public/thumbnail/${tool.thumbnail || 'cv.png'}`)}
                            width={340}
                            height={194}
                            placeholder="blur"
                            className="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-105"
                          />
                        ) : <Image
                          alt={`${tool.name} thumbnail`}
                          src={tool.thumbnail}
                          loader={imageLoader}
                          width={340}
                          height={194}
                          className="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-105"
                        />}
                      </Link>
                    </CardContent>
                  )}
                  
                  <CardFooter className="flex flex-col items-start p-4 flex-grow">
                    <div className="flex justify-between w-full mb-2">
                      <Link href={tool.href} prefetch={false} className="text-lg font-medium group-hover:text-orange-500 transition-colors">
                        {tool.name}
                      </Link>
                    </div>
                    <p className="text-sm text-muted-foreground">{tool.description}</p>
                  </CardFooter>
                </Card>
              </motion.div>
            )
          })}
        </div>
      ) : (
        <div className="mb-8 space-y-4">
          {tools.map((tool, index) => {
            const isStar = starTools.map(x => x.href).includes(tool.href)
            return (
              <motion.div
                key={tool.href}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.05 }}
              >
                <Card
                  className="group hover:border-orange-400 hover:shadow-md hover:bg-orange-50 cursor-pointer transition-all duration-300 relative overflow-hidden"
                  onClick={(e) => {
                    if ((e.target as HTMLElement).closest('button')) return;
                    router.push(tool.href) 
                  }}
                >
                  <div className="flex flex-col md:flex-row">
                    {showThumbnail && (
                      <div className="md:w-48 shrink-0">
                        <Link href={tool.href} className="block h-full overflow-hidden" prefetch={false}>
                          {!tool.thumbnail.startsWith('images/') ? (
                            <Image
                              alt={`${tool.name} thumbnail`}
                              src={require(`../public/thumbnail/${tool.thumbnail || 'cv.png'}`)}
                              width={340}
                              height={194}
                              placeholder="blur"
                              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                            />
                          ) : <Image
                            alt={`${tool.name} thumbnail`}
                            src={tool.thumbnail}
                            loader={imageLoader}
                            width={340}
                            height={194}
                            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                          />}
                        </Link>
                      </div>
                    )}
                    
                    <div className="flex-1 p-4 flex justify-between">
                      <div>
                        <Link href={tool.href} prefetch={false} className="text-lg font-medium group-hover:text-orange-500 transition-colors block mb-2">
                          {tool.name}
                        </Link>
                        <p className="text-sm text-muted-foreground">{tool.description}</p>
                      </div>
                      
                      <div className="flex flex-col items-end justify-between ml-4">
                        <Button
                          size="icon"
                          variant={isStar ? "default" : "outline"}
                          onClick={(e) => {
                            e.stopPropagation();
                            onStar(tool);
                          }}
                          className={`rounded-full w-8 h-8 ${isStar ? 'bg-orange-500 hover:bg-orange-600' : 'border-orange-200'}`}
                        >
                          <StarIcon className={`h-4 w-4 ${isStar ? 'text-white' : 'text-orange-500'}`} />
                        </Button>
                        
                        <Button
                          size="icon"
                          variant="ghost"
                          className="mt-2 text-gray-500 hover:text-orange-500"
                          onClick={(e) => {
                            e.stopPropagation();
                            router.push(tool.href);
                          }}
                        >
                          <ExternalLinkIcon className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </motion.div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export function StarTools({ 
  starTools, 
  onStar, 
  showThumbnail = true,
  viewMode = 'grid'
}: { 
  starTools: Tool[], 
  onStar: (tool: Tool) => void, 
  showThumbnail?: boolean,
  viewMode?: 'grid' | 'list'
}) {
  return starTools && starTools.length ?
    <ToolsBlock 
      key="star" 
      label="star" 
      tools={starTools} 
      onStar={onStar} 
      starTools={starTools || []} 
      showThumbnail={showThumbnail}
      viewMode={viewMode}
    /> : null
}
